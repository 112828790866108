import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { HttpResponseStatus } from './api/http-response-status';
import { RollbarApiError } from '../../rollbar';
import { SessionService } from './api/session.service';
import { YYYYMMDDString } from '@rootTypes';
import { BaseApiService } from './api/base-api.service';
import { firstValueFrom } from 'rxjs';

export interface ExportRouteSheetData {
  fileName: string;
  fileBase64: string;
}

export interface ExportRouteOptions {
  includeStudentDetails?: boolean;
  includeDrivingDirections?: boolean;
  dateRange: {
    from: YYYYMMDDString;
    to: YYYYMMDDString;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ExportRouteApiService {
  constructor(
    private http: HttpClient,
    private session: SessionService,
    private baseApiService: BaseApiService,
  ) {}

  public async exportRoute(routeId: string, options?: ExportRouteOptions): Promise<ExportRouteSheetData> {
    const res = await firstValueFrom(this.baseApiService.postBlob('exportRoute', { routeId, ...options }));
    const fileName = this.getFileNameFromResponse(res);
    console.log(fileName);
    return {
      fileName,
      fileBase64: await this.blobToBase64(res.data.blob),
    };
  }

  private async getRouteSheetBlobWithRetry(routeId: string, options?: ExportRouteOptions): Promise<HttpResponse<Blob>> {
    try {
      return await this.getRouteSheetBlob(routeId, options);
    } catch (error) {
      // Note, HttpErrorResponse.error contains Blob of json with error details,
      // this is a known Angular issue https://github.com/angular/angular/issues/19888#issuecomment-522119151.
      // We should apply the workaround using interceptors to read the error details from Blob
      // against similar HTTP responses (for example, downloadImage API).
      // For now we can use HttpErrorResponse.message with general error information.
      const firstAttemptError = error as HttpErrorResponse;
      if (firstAttemptError.status === HttpResponseStatus.UNAUTHORIZED) {
        try {
          return this.session.refreshSessionAndRetry(() => this.getRouteSheetBlob(routeId));
        } catch (retryError) {
          console.error(new RollbarApiError('exportRoute', retryError.status), retryError);
          throw retryError;
        }
      } else {
        console.error(new RollbarApiError('exportRoute', firstAttemptError.status), firstAttemptError);
        throw firstAttemptError;
      }
    }
  }

  private getRouteSheetBlob(routeId: string, options?: ExportRouteOptions): Promise<HttpResponse<Blob>> {
    let url = `${wpEnvironment.apiBaseUrl}/${wpEnvironment.userRole}/exportRoute?routeId=${routeId}`;
    if (options?.includeStudentDetails) {
      url = `${url}&includeStudentDetails=true`;
    }
    if (options?.includeDrivingDirections) {
      url = `${url}&includeDrivingDirections=true`;
    }
    return this.http
      .get(url, {
        observe: 'response',
        responseType: 'blob',
        withCredentials: true,
        params: {
          includeStudentDetails: options.includeStudentDetails,
          includeDrivingDirections: options.includeDrivingDirections,
          dateRange: JSON.stringify(options.dateRange),
        },
      })
      .toPromise();
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const dataUrl = reader.result as string;
        resolve(dataUrl);
      };
      reader.onerror = () => {
        reject(new Error('Unable to read Blob'));
      };
      reader.readAsDataURL(blob);
    });
  }

  private getFileNameFromResponse(res: HttpResponse<Blob>): string {
    // An example of the header value: 'attachment; filename=John Smith (AM).pdf'
    return res.headers.get('content-disposition').split('filename=')[1];
  }
}
