import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpApiService } from './http-api.service';
import { ConfirmationApiService } from '../../prompt-api';

@Injectable({
  providedIn: 'root',
})
export class BaseApiService {
  constructor(
    private httpApi: HttpApiService,
    private promptApi: ConfirmationApiService,
  ) {}

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public get(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.get(apiName, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   * @param overrideApiCall pass it to test API prompt responses
   */
  public post(
    apiName: string,
    request: any = {},
    prefixEndpoint?: string,
    overrideApiCall?: (request: any) => Promise<any>,
  ): Observable<any> {
    const apiStream$ =
      overrideApiCall ??
      ((r) =>
        this.httpApi.post(apiName, r, prefixEndpoint).then((res) => {
          if (!res.data) {
            console.warn('Bad response format');
            console.log(res);
            return res;
          }
          return res.data;
        }));
    // will return response data, when confirmed,
    // or throw RejectedByUserError, if not,
    // when not confirmed, so that the features
    // can handle the rejection
    return from(this.promptApi.handle(apiStream$, request));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public postBlob(apiName: string, request: any = {}, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.postBlob(apiName, request, prefixEndpoint));
  }

  public postFormData(apiName: string, formData: FormData, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.postFormData(apiName, formData, prefixEndpoint));
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public getBlob(apiName: string, prefixEndpoint?: string): Observable<any> {
    return from(this.httpApi.getBlob(apiName, prefixEndpoint));
  }
}
