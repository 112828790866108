<!--matPrefix and matSuffix are for consistent paddings-->
<ng-container *ngIf="model">
  <!--  Fixed select-->
  <div *ngIf="!model.isEntitySearch">
    <mat-form-field class="wp-select" [hideRequiredMarker]="true">
      <mat-label>{{model.label}}</mat-label>
      <div matPrefix></div>
      <mat-select
        [id]="id"
        [tabIndex]="tabIndex"
        [formControl]="model.control"
        [compareWith]="fixedSelectCompareFn "
        class="smart-select"
      >
        <mat-option
          *ngFor="let opt of model.fixedOptions"
          [disabled]="opt.isInvalidOption"
          [value]="{ id: opt.value, label: opt.displayLabel}"
        >
          <span [ngStyle]="opt.isInvalidOption ? { textDecoration: 'line-through'} : {}"> {{opt.displayLabel}} </span>
        </mat-option>
      </mat-select>
      <div class="fixed-select-suffix" matSuffix>
        <ng-container *ngIf="model.control?.value?.id && !model.control.disabled; else arrowIcon">
          <wp-icon-close
            [size]="'small'"
            (click)="model.onOptionSelect(undefined); clearClicked.emit()"
          ></wp-icon-close>
        </ng-container>
        <ng-template #arrowIcon>
          <wp-icon-arrow-down></wp-icon-arrow-down>
        </ng-template>
      </div>
      <mat-error *ngIf="model.control.invalid && model.control.hasError('required')"> Required field </mat-error>
      <mat-error *ngIf="model.control.invalid && model.control.hasError(model.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY)">
        Please select value from the list
      </mat-error>
      <mat-error
        *ngIf="model.customErrorMessageKey && model.control.invalid && model.control.hasError(model.customErrorMessageKey)"
      >
        {{model.control.errors[model.customErrorMessageKey]}}
      </mat-error>
    </mat-form-field>
  </div>
  <!--  Entity search-->
  <ng-container *ngIf="model.isEntitySearch">
    <wp-entity-filter
      [id]="id"
      [searchInputControl]="model.displayFormControl"
      [searchByType]="model.entitySearchType"
      [placeholder]="model.label"
      [isSearchGlassIcon]="false"
      [inputCustomErrorMessageKey]="model?.SELECT_VALUE_FROM_THE_LIST_ERROR_KEY"
      [entityStatus]="model.entitySearchStatus ??  entityFilterStatus"
      [districtIds]="model?.entitySearchParams?.districtIds || []"
      [campusIds]="model?.entitySearchParams?.campusIds || []"
      [operatorIds]="model?.entitySearchParams?.operatorIds || []"
      [parentIds]="model?.entitySearchParams?.parentIds || []"
      [driverIds]="model?.entitySearchParams?.driverIds || []"
      [vendorIds]="model?.entitySearchParams?.vendorIds || []"
      [yardIds]="model?.entitySearchParams?.yardIds || []"
      [districtProgramIds]="model?.entitySearchParams?.districtProgramIds || []"
      [extraFilters]="model?.entitySearchParams?.extraFilters"
      [tabIndex]="tabIndex"
      (inputEvent)="onInputEvent()"
      (inputCleared)="onEntityOptionSelect(undefined)"
      (selected)="onEntityOptionSelect($event)"
    >
      <div *ngIf="prefixTemplate" class="input-prefix">
        <ng-template [ngTemplateOutlet]="prefixTemplate"></ng-template>
      </div>
      <div *ngIf="suffixTemplate" class="input-suffix">
        <ng-template [ngTemplateOutlet]="suffixTemplate"></ng-template>
      </div>
    </wp-entity-filter>
  </ng-container>
</ng-container>
