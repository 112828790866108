import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { API_ID, X_APP_PLATFORM, X_APP_VERSION, X_REQ_ID } from './http-req-headers';
import { HttpResponseStatus } from './http-response-status';
import { SessionService } from './session.service';
import { RollbarApiError } from '../../../rollbar/utils/rollbar-api-error';
import { WpError } from '@rootTypes';
import { take } from 'rxjs/operators';

const appGitVersion = wpEnvironment.gitVersion;
const appPlatform = 'URL' in window ? new URL(window.location.href).hostname : (window as any).origin;

export class ServerResponse {
  code!: number;
  data!: any;
}

export class PostRequestData {
  constructor(
    public api: string,
    public reqObj: any = {},
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class HttpApiService {
  private userRole = wpEnvironment.userRole;

  constructor(
    private sessionService: SessionService,
    private http: HttpClient,
  ) {}

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public async get(apiName: string, prefixEndpoint?: string): Promise<any> {
    try {
      const result = await this.getRequest(apiName, prefixEndpoint);
      return result;
    } catch (httpError) {
      console.error(new RollbarApiError(`GET ${apiName}`, httpError.status), httpError.error);
      throw httpError.error;
    }
  }

  public async getBlob(apiName: string, prefixEndpoint?: string): Promise<any> {
    try {
      const result = await this.getRequest(apiName, prefixEndpoint, 'blob');
      return result;
    } catch (httpError) {
      const error = await this.errorBlobToError(httpError.error);
      console.error(new RollbarApiError(`GET ${apiName}`, httpError.status), error);
      throw error;
    }
  }

  /**
   * @param prefixEndpoint if omitted wpEnvironment.userRole is used
   */
  public async post(apiName: string, payload: any = {}, prefixEndpoint?: string): Promise<any> {
    try {
      const result = await this.postRequest(apiName, payload, prefixEndpoint);
      return result;
    } catch (httpError) {
      console.error(new RollbarApiError(apiName, httpError.status), httpError.error);
      throw httpError.error;
    }
  }

  public async postBlob(apiName: string, payload: any = {}, prefixEndpoint?: string): Promise<any> {
    try {
      const result = await this.postRequest(apiName, payload, prefixEndpoint, 'blob');
      return result;
    } catch (httpError) {
      const error = await this.errorBlobToError(httpError.error);
      console.error(new RollbarApiError(apiName, httpError.status), error);
      throw error;
    }
  }

  public async postFormData(apiName: string, formData: FormData, prefixEndpoint?: string): Promise<any> {
    const sendRequest = async (): Promise<ServerResponse> => {
      const res = await this.http
        .post(this.getEndpoint(apiName, prefixEndpoint), formData, {
          withCredentials: true,
          observe: 'response',
          responseType: 'json',
        })
        .pipe(take(1))
        .toPromise();
      return res.body as ServerResponse;
    };

    try {
      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} request`);
      }
      const response = await sendRequest();
      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} success`, response);
      }
      return response;
    } catch (error) {
      const errorResponse = error as HttpErrorResponse;
      if (errorResponse.status === HttpResponseStatus.UNAUTHORIZED) {
        return await this.sessionService.refreshSessionAndRetry(sendRequest);
      } else {
        console.error(new RollbarApiError(apiName, errorResponse.status), errorResponse.error);
        throw errorResponse.error;
      }
    }
  }

  private async getRequest(
    apiName: string,
    prefixEndpoint?: string,
    responseType?: 'blob' | 'json' | 'text',
  ): Promise<ServerResponse> {
    const sendRequest = () =>
      this.sendGetRequest(apiName, this.createRequestHeaders(apiName), prefixEndpoint, responseType);
    try {
      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} request`);
      }

      const response: ServerResponse = await sendRequest();

      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} success`, response);
      }
      return response;
    } catch (error) {
      const errorResponse = error as HttpErrorResponse;
      if (errorResponse.status === HttpResponseStatus.UNAUTHORIZED) {
        return await this.sessionService.refreshSessionAndRetry(sendRequest);
      } else {
        throw errorResponse;
      }
    }
  }

  private async postRequest(
    apiName: string,
    payload: any = {},
    prefixEndpoint?: string,
    responseType?: 'blob' | 'json' | 'text',
  ): Promise<ServerResponse> {
    const data = new PostRequestData(apiName, payload);
    const sendRequest = () =>
      this.sendPostRequest(apiName, data, this.createRequestHeaders(apiName), prefixEndpoint, responseType);
    try {
      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} request`, payload);
      }

      const response: ServerResponse | ArrayBuffer = await sendRequest();

      if (wpEnvironment.apiLogsEnabled) {
        console.log(`${apiName} success`, response);
      }
      return response;
    } catch (error) {
      const errorResponse = error as HttpErrorResponse;
      if (errorResponse.status === HttpResponseStatus.UNAUTHORIZED) {
        return await this.sessionService.refreshSessionAndRetry(sendRequest);
      } else {
        throw errorResponse;
      }
    }
  }

  private sendGetRequest(
    apiName: string,
    headers: HttpHeaders,
    prefixEndpoint?: string,
    responseType: 'blob' | 'json' | 'text' = 'json',
  ): Promise<ServerResponse> {
    return (
      this.http
        .get(this.getEndpoint(apiName, prefixEndpoint), {
          headers,
          withCredentials: true,
          observe: 'response',
          responseType: responseType as any,
        })
        .toPromise() as Promise<HttpResponse<object> | HttpResponse<ArrayBuffer> | HttpResponse<Blob>>
    ).then((response) => {
      if (response.body instanceof ArrayBuffer || response.body instanceof Blob) {
        return {
          code: 200,
          data: {
            blob: this.blobOrBufferToBlob(response.body),
            fileName: this.getFileNameFromResponse(response),
          },
        };
      }
      return response.body as ServerResponse;
    });
  }

  private sendPostRequest(
    apiName: string,
    reqData: PostRequestData,
    headers: HttpHeaders,
    prefixEndpoint?: string,
    responseType: 'blob' | 'json' | 'text' = 'json',
  ): Promise<ServerResponse> {
    return (
      this.http
        .post(this.getEndpoint(apiName, prefixEndpoint), reqData, {
          headers,
          withCredentials: true,
          observe: 'response',
          responseType: responseType as any,
        })
        .toPromise() as Promise<HttpResponse<object> | HttpResponse<ArrayBuffer> | HttpResponse<Blob>>
    ).then((response) => {
      if (response.body instanceof ArrayBuffer || response.body instanceof Blob) {
        return {
          code: 200,
          headers: response.headers,
          data: {
            blob: this.blobOrBufferToBlob(response.body),
            fileName: this.getFileNameFromResponse(response),
          },
        };
      }
      return response.body as ServerResponse;
    });
  }

  private createRequestHeaders(apiName: string): HttpHeaders {
    const headers = new HttpHeaders();
    return headers
      .append(X_REQ_ID, this.sessionService.generateRequestId())
      .append(API_ID, apiName)
      .append(X_APP_VERSION, appGitVersion)
      .append(X_APP_PLATFORM, appPlatform);
  }

  private getEndpoint(apiName: string, prefixEndpoint?: string): string {
    return `${wpEnvironment.apiBaseUrl}/${this.getPrefixedApiName(apiName, prefixEndpoint)}`;
  }

  private getPrefixedApiName(apiName: string, prefixEndpoint?: string): string {
    let prefix: string;
    if (typeof prefixEndpoint === 'string') {
      if (prefixEndpoint.length) {
        prefix = `${prefixEndpoint}/`;
      } else {
        prefix = '';
      }
    } else {
      prefix = `${this.userRole}/`;
    }
    return `${prefix}${apiName}`;
  }

  private blobOrBufferToBlob(source: ArrayBuffer | Blob): Blob {
    return new Blob([source]);
  }

  private getFileNameFromResponse(res: HttpResponse<any>): string {
    const headerValue = res.headers.get('content-disposition');
    if (headerValue) {
      // An example of the header value: 'attachment; filename=John Smith (AM).pdf'
      try {
        return headerValue.split('filename=')[1].split('.')[0];
      } catch (error) {
        console.error('Failed to read file name for: ', headerValue);
        return undefined;
      }
    }
    return undefined;
  }

  private async errorBlobToError(errorBlob: Blob): Promise<any> {
    try {
      return JSON.parse(await errorBlob.text());
    } catch (originalError) {
      const error: WpError = {
        text: 'Failed to parse error from Blob response',
      };
      return error;
    }
  }
}
