import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanBeConfirmationToken, CanRequireConfirmation, ConfirmationOption, ConfirmationResponse } from './types';
import { ConfirmPopupConfig, PopupableOptions } from '../../core/popupable/types';
import { PopupableService } from '../../core/popupable/popupable.service';
import { isConfirmationResponse } from './is-confirmation-response';
import { PromptConfirmationPopupComponent } from '../../containers/prompt-confirmation-popup/prompt-confirmation-popup.component';
import { APIPromptDismissedByUserError } from './types/api-prompt-dismissed-by-user-error';

export type ConfirmationStream<T = any> = (response: CanRequireConfirmation<T>) => Observable<ConfirmationOption>;

const defaultPopupConfig: ConfirmPopupConfig = {
  header: 'Confirm action',
  popupWidthPx: 450,
  confirmBtnText: 'OK',
  confirmBtnColor: 'green',
  cancelBtnText: 'Cancel',
  confirmBtnWidthPx: 150,
  cancelBtnWidthPx: 150,
};

const defaultPopupOptions: PopupableOptions = {
  pageMaskZIndex: 14,
  contentZIndex: 15,
  closePrevious: false,
};

@Injectable({ providedIn: 'root' })
export class ConfirmationApiService {
  constructor(private popupableService: PopupableService) {}

  public async handle<Request, Response>(
    apiRequestFn: (request: Request) => Promise<Response>,
    request: Request,
    overrideConfirmPopupConfig?: ConfirmPopupConfig,
    overrideConfirmPopupOptions?: PopupableOptions,
    confirmationStream?: ConfirmationStream,
  ): Promise<Response> {
    const finalConfirmationStream =
      confirmationStream || this.getDefaultConfirmationStream(overrideConfirmPopupConfig, overrideConfirmPopupOptions);

    const response = await apiRequestFn(request);
    if (!isConfirmationResponse(response)) {
      return response;
    }

    const option = await finalConfirmationStream(response).toPromise();
    if (!option?.promptId || option?.action === 'dismiss') {
      throw new APIPromptDismissedByUserError('User dismissed api prompt');
    } else {
      return this.handle<any, Response>(
        apiRequestFn as (request: CanBeConfirmationToken<Request>) => Promise<Response>,
        {
          promptToken: response.apiPrompt.promptToken,
          promptId: option.promptId,
        },
        overrideConfirmPopupConfig,
        overrideConfirmPopupOptions,
        confirmationStream,
      );
    }
  }

  private getDefaultConfirmationStream(
    popupConfigOverride: ConfirmPopupConfig = {},
    popupOptionsOverride: PopupableOptions = {},
  ): ConfirmationStream {
    const finalConfig: ConfirmPopupConfig = {
      ...defaultPopupConfig,
      ...popupConfigOverride,
    };
    const finalOptions: PopupableOptions = {
      ...defaultPopupOptions,
      ...popupOptionsOverride,
    };
    return (response: CanRequireConfirmation<any>) => {
      return this.popupableService.openApiPromptPopup<any, ConfirmationResponse, ConfirmationOption>(
        PromptConfirmationPopupComponent,
        response as ConfirmationResponse,
        { ...finalOptions },
      );
    };
  }
}
